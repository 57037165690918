// AdminHeader.jsx
import React, { useState } from "react";
import "./../css/AdminHeader.css"; // Import the admin header styles

const AdminHeader = () => {
  const adminName = "Admin"; // Replace with the actual admin name
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="admin-header">
      <h1>Admin Portal</h1>
      <div className="user-welcome">Welcome, {adminName}!</div>
      <div
        className={`menu-toggle ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <nav className={`nav-links ${isMenuOpen ? "open" : ""}`}>
        <a href="/">Home</a>
        <a href="/dashboard">Dashboard</a>
        <a href="/settings">Settings</a>
        {/* Add more navigation links as needed */}
      </nav>
    </header>
  );
};

export default AdminHeader;
