import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import './EditJRM.css'; // Import the CSS file

const EditJRM = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [jrmData, setJrmData] = useState({
        heading: '',
        image: '',
        description: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch existing JRM data
    useEffect(() => {
        const fetchJRMData = async () => {
            try {
                const response = await fetch(`https://discountparlour.com/api/get/${slug}`);
                if (!response.ok) {
                    throw new Error('Error fetching JRM data');
                }
                const data = await response.json();
                setJrmData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchJRMData();
    }, [slug]);

    // Handle form submission for editing
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://discountparlour.com/api/editJRM/${slug}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jrmData),
            });

            if (!response.ok) {
                throw new Error('Error updating JRM data');
            }

            navigate(`/JustReleasedMovies/${slug}`); // Redirect after editing
        } catch (error) {
            setError(error.message);
        }
    };

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setJrmData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle description change from TinyMCE
    const handleEditorChange = (content) => {
        setJrmData((prevData) => ({
            ...prevData,
            description: content,
        }));
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="edit-jrm">
            <h1>Edit JRM</h1>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="heading">Heading:</label>
                    <input
                        type="text"
                        id="heading"
                        name="heading"
                        value={jrmData.heading}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="image">Image URL:</label>
                    <input
                        type="text"
                        id="image"
                        name="image"
                        value={jrmData.image}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <Editor
                    apiKey="88ajl7cuhzzi0wa2dulizl34yc8ll9heqp4wsy8l16dkr71i" // Optional: Add your TinyMCE API key here
                        id="description"
                        name="description"
                        initialValue={jrmData.description}
                        init={{
                            height: 400,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'link image | removeformat | help',
                        }}
                        onEditorChange={handleEditorChange}
                    />
                </div>
                <button type="submit">Update JRM</button>
            </form>
        </div>
    );
};

export default EditJRM;
