import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BrandImage1 from "./../objects/PUMA.jpg";
import BrandImage2 from "./../objects/Nike.jpg";
import BrandImage3 from "./../objects/clothes.jpg";
import "./../css/BrandSection.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'; // Import the angle left and angle right icons from Font Awesome


const BrandSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const brandImages = [BrandImage1, BrandImage2, BrandImage3];
  const navigate = useNavigate(); // Get the navigate function from React Router

  const handleMove = (direction) => {
    const nextIndex =
      (currentImageIndex + direction + brandImages.length) % brandImages.length;
    setCurrentImageIndex(nextIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleMove(1);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [currentImageIndex]);

  const exploreCoupons = () => {
    // Use the navigate function to move to the 'coupons' route
    navigate("/coupons");
  };

  return (
    <div className="brand-section">
        <button className="nav-button left11" onClick={() => handleMove(-1)}>
        <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      <div className="image-container">
        {brandImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="Brand Image"
            className={`brand-image ${
              index === currentImageIndex ? "brand-image-current" : ""
            } ${index === (currentImageIndex + 1) % brandImages.length ? "brand-image-next" : ""}`}
          />
        ))}
      
      </div>
      
      <button className="nav-button right" onClick={() => handleMove(1)}>
      <FontAwesomeIcon icon={faAngleRight} /> 
        </button>
      <div className="explore-container">
        <button className="explore-button" onClick={exploreCoupons}>
          Explore Coupons
        </button>
      </div>
    </div>
  );
};

export default BrandSection;
