import React from "react";
import "./../css/Learn.css"; // Update with your Learn page-specific styles
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";

const Learn = () => {
  return (
    <div className="learn-container">
      <Header />

      <div className="learn-content">
        <h1>Learn More</h1>
        <p>
          Welcome to the learning hub of Discount Shop! Here, we're excited to
          share valuable information about our platform and how you can make the
          most out of your free coupons.
        </p>
        <h2>Why Choose Discount Shop Coupons?</h2>
        <p>
          At Discount Shop, we are committed to bringing you the best deals and
          discounts. Discover how our free coupons can help you save money on
          your favorite products and enhance your overall shopping experience.
        </p>
        <h2>How Discount Shop Coupons Work</h2>
        <p>
          Learn about the simple process of using our coupons. From finding the
          latest offers to applying them during checkout, we've got you covered.
        </p>
        <h2>Community and Engagement</h2>
        <p>
          Discount Shop is more than just a coupon platform; it's a community.
          Find out how you can connect with other users, share your experiences,
          and stay updated on the latest trends in savings.
        </p>
        <h2>Getting Support</h2>
        <p>
          Have questions or concerns? Our support team is here to help. Learn
          how to reach out to us and get the assistance you need for a seamless
          experience.
        </p>
      </div>

      <Footer />
      <Rights />
    </div>
  );
};

export default Learn;
