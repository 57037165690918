// Careers.jsx

import React from "react";
import "./../css/Careers.css"; // Include your own stylesheet
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";

const Careers = () => {
  const contactEmail = "abubakerasghar125@gmail.com";
  return (
    <div className="careers-container">
      <Header />

      <div className="careers-content">
        <div id="about-discountparlour">
          <h1>About Discount Parlour</h1>
          <p>
            Welcome to Discount Parlour, your go-to destination for incredible
            savings, free coupons, and exciting gifts! At Discount Parlour, we
            are dedicated to bringing joy and value to our customers by offering
            a wide range of discounts and exclusive deals on various products
            and services.
          </p>

          <h2>Explore Amazing Discounts and Coupons</h2>
          <p>
            Discover an extensive collection of free coupons and discount codes
            for your favorite brands and retailers. Whether you're shopping for
            electronics, fashion, groceries, or travel, Discount Parlour has you
            covered. Our team works tirelessly to curate the best deals,
            ensuring that you save money on every purchase.
          </p>

          <h2>Unlock Exciting Gifts and Rewards</h2>
          <p>
            As a valued member of Discount Parlour, you'll have access to
            exclusive rewards and gifts. From special promotions to loyalty
            programs, we believe in rewarding our users for their continued
            support. Keep an eye out for exciting giveaways and contests where
            you can win amazing prizes!
          </p>

          <h2>Stay Updated for Future Opportunities</h2>
          <p>
            While we currently do not have any career opportunities available at
            Discount Parlour, we appreciate your interest in joining our team.
            Be sure to bookmark our website and follow us on social media to
            stay informed about any future job openings. In the meantime,
            continue enjoying the fantastic discounts and gifts we have to
            offer!
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions, feedback, or inquiries about Discount
            Parlour, please don't hesitate to reach out to us. Our dedicated
            team is here to assist you. You can contact us via email at
            <a href={`mailto:${contactEmail}`}> contact us</a>. or connect with
            us on social media.
          </p>
        </div>
      </div>

      <Footer />
      <Rights />
    </div>
  );
};

export default Careers;
