// PrivacyPolicy.jsx

import React from "react";
import "./../css/PrivacyPolicy.css"; // Include your own stylesheet
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";

const PrivacyPolicy = () => {
  const contactEmail = "discountparlour@gmail.com";
  return (
    <div className="privacy-policy-container">
      <Header />

      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p>
          At Discount Shop, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy outlines the types of information we collect, how we use it,
          and the measures we take to safeguard your data.
        </p>
        <h2>Information We Collect</h2>
        <p>
          We may collect personal information, such as your name and email
          address, when you sign up for our services or subscribe to our
          newsletter. Additionally, we may gather non-personal information, such
          as browser type and IP address, to enhance your user experience.
        </p>
        <h2>How We Use Your Information</h2>
        <p>
          The information we collect is used to personalize your experience,
          provide customer support, and deliver relevant content. We may also
          use your data for analytics and to improve our website's performance.
        </p>
        <h2>Sharing Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties. However, we may share your data with trusted service
          providers who assist us in operating our website, conducting business,
          or servicing you.
        </p>
        <h2>Security Measures</h2>
        <p>
          We implement various security measures to protect your personal
          information. These measures include encryption, secure sockets layer
          (SSL) technology, and regular vulnerability scans.
        </p>
        <h2>Changes to Privacy Policy</h2>
        <p>
          We reserve the right to modify this Privacy Policy at any time.
          Changes will be effective immediately upon posting. We encourage you
          to review this policy periodically for any updates.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about our Privacy Policy, please
          <a href={`mailto:${contactEmail}`}> contact us</a>.
        </p>
      </div>

      <Footer />
      <Rights />
    </div>
  );
};

export default PrivacyPolicy;
