import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'; 

const SidePanelContainer = styled.div`
  background-color: none;
  padding: 20px;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  
  @media (max-width: 767px) {
   
  margin-top: 2px;
  }
  @media (min-width: 1200px) {
   
    margin-right:62px;
  }

  @media (min-width: 1300px) {
    
    margin-right:57px;
 
  }


  /* For larger screens, you can adjust as needed */
  @media (min-width: 1441px) {
    margin-right:48px;

  }

  @media (min-width: 1601px) {

    margin-right:51px;
  }

  @media (min-width: 1801px) {
    margin-right:29px;
  }
  
  @media (min-width: 1950px) {
    margin-right:20px;
  }


  @media (min-width: 2101px) {
    margin-right:0px;
  }
  @media (min-width: 2501px) {
  margin-right:0px;
    }
`;

const Title = styled.h2`
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 300; /* Adjust the font weight to make it thinner */
  font-family: Arial, sans-serif; /* Choose an appropriate font family */
  
  @media (min-width: 1200px) {
   
    margin-left:-64vw;
  }

  @media (min-width: 1300px) {
    
    margin-left:-54vw;
 
  }
  @media (max-width: 1030px) {
    
    margin-left:-72vw;
  }


  /* For larger screens, you can adjust as needed */
  @media (min-width: 1441px) {
    
    margin-left:-50vw;

  }

  @media (min-width: 1601px) {
    
    margin-left:-47vw;

  }

  @media (min-width: 1801px) {
    margin-left:-40vw;
  }
  
  @media (min-width: 1950px) {

    margin-left:-37vw;
  }


  @media (min-width: 2101px) {
    
  margin-left:-33vw;
  
  }
  @media (min-width: 2501px) {
  
    margin-left:-26vw;
    }

    @media (max-width: 767px) {
      font-size:20px;
      
    margin-left:-50vw;
    }
`;
const ShopList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  background-color: white;
  flex-wrap: nowrap; /* Ensure items stay in a single row */
  justify-content: flex-start; /* Align items to the start of the row */
  overflow: hidden; /* Hide overflowing content */
  
 
`;

const ShopListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 0 0 auto; /* Each item takes its own width */
  border: 1px solid white;
  &:hover {
    border: 1px solid yellow;
  }
  span {
    @media (max-width: 767px) {
   
      font-size: 0.4rem;
      }
  }

 
 

`;

const ShopImage = styled.img`
width: 170px;
height: 120px;
  margin-bottom: 5px;

  
  @media (max-width: 768px) {
  
   }
   @media (max-width: 767px) {
    width: 57px;
    height: 31px
    }
 
 
 
   @media (min-width: 900px) {
    width: 152px;
    height: 120px
   }
 
 
   @media (min-width: 1200px) {
    width: 163px;
    height: 120px
   }
 
   @media (min-width: 1300px) {
    width: 157px;
    height: 124px;
   }
 
 
   /* For larger screens, you can adjust as needed */
   @media (min-width: 1441px) {
    width: 161px;
    height: 124px;
   
 
   }
 
   @media (min-width: 1601px) {
    width: 166px;
    height: 124px;

   }
 
   @media (min-width: 1801px) {
    width: 163px;
    height: 124px;
   }
   
   @media (min-width: 1950px) {
    width: 157px;
    height: 124px;
   }
 
 
   @media (min-width: 2101px) {
    width: 158px;
    height: 120px;
   
   }
   @media (min-width: 2501px) {
    width: 169px;
    height: 140px;
   
     }
`;

const Mobile = styled.div`
position:absolute;
  display: none;
  top:570px;
right:1.8vw;
  @media (max-width: 767px) {
    display: block;
    margin: 0;
    padding: 0;
    top:310px;
  }

  @media (max-width: 1024px) {
    display: block;
    margin: 0;
    padding: 0;
  }

`;
const Mobile2 = styled.div`
  display: none;
  top:570px;
right:1.8vw;
  @media (max-width: 767px) {
    display: block;
    margin: 0;
    padding: 0;
  }


`;
const Laptop= styled.div`
display: block;
margin: 0;
padding: 0;

  @media (max-width: 768px) {

    display: none;
  }

  

`;

const FeaturedCouponContainer = styled.div`
  margin-bottom: 20px;
`;

const FeaturedCoupon = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
`;
const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 60px;
`;

const Button = styled.button`
  background-color: transparent;
  color: rgb(178, 177, 177);
  border: none;
  cursor: pointer;
  margin: 0 -10px;
  font-size: 1.5rem;

  &:hover {
    color: rgb(110, 110, 110);
    background-color: transparent;
  }

    @media (max-width: 767px) {
    
    font-size: 1rem;
    }

  

`;


const SidePanel = () => {
  const [shopNames, setShopNames] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [navigateIndex, setNavigateIndex] = useState(0);
  const navigate = useNavigate();

  const handleShopSelect = (selectedShop) => {
    navigate(`/ShopCoupons/${selectedShop.shop_name}`);
  };

  useEffect(() => {
    fetch("https://discountparlour.com/api/shops")
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error("API error:", data.error);
        } else {
          console.log("API response:", data);
          setShopNames(Array.isArray(data) ? data : [data]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    setNavigateIndex(startIndex);
  }, [startIndex]);

  const handleMove = (direction) => {
    const newStartIndex = startIndex + direction * 5;
    if (newStartIndex >= 0 && newStartIndex <= shopNames.length - 5) {
      setStartIndex(newStartIndex);
    }
  };
  const handleMove2 = (direction) => {
    const newStartIndex = startIndex + direction * 4;
    if (newStartIndex >= 0 && newStartIndex <= shopNames.length - 4) {
      setStartIndex(newStartIndex);
    }
  };

  const displayedShops = shopNames.slice(startIndex, startIndex + 5);
  
  const displayedShops2 = shopNames.slice(startIndex, startIndex + 4);
  const featuredCoupons = shopNames.slice(0, Math.min(5, shopNames.length));

  return (
    <SidePanelContainer>
  <Title>Featured Stores</Title>
<Mobile>
  <NavigationButtons>
        <Button onClick={() => handleMove2(-1)} disabled={startIndex === 0}>
        <FontAwesomeIcon icon={faAngleLeft} /> 
        </Button>
        <Button
          onClick={() => handleMove2(1)}
          disabled={startIndex === shopNames.length - 4}
        >
        <FontAwesomeIcon icon={faAngleRight} /> 
        </Button>
      </NavigationButtons>
      </Mobile>
     
  <NavigationButtons>
  <Laptop>
    <ButtonContainer>
      <Button onClick={() => handleMove(-1)} disabled={startIndex === 0}>
      <FontAwesomeIcon icon={faAngleLeft} />
      </Button>
    </ButtonContainer>
    </Laptop>
    <Laptop>
    <ShopList>
  
  {displayedShops.map((shop, index) => (
    <ShopListItem key={index} onClick={() => handleShopSelect(shop)}>
      {shop.shop_image_path && (
        <ShopImage
          src={`https://discountparlour.com/${shop.shop_image_path}`}
          alt="Shop Image"
        />
      )}
      <span>{shop.shop_name}</span>
    </ShopListItem>
  ))}
</ShopList>
</Laptop>
<Mobile2>
    <ShopList>
  
  {displayedShops2.map((shop, index) => (
    <ShopListItem key={index} onClick={() => handleShopSelect(shop)}>
      {shop.shop_image_path && (
        <ShopImage
          src={`https://discountparlour.com/${shop.shop_image_path}`}
          alt="Shop Image"
        />
      )}
      <span>{shop.shop_name}</span>
    </ShopListItem>
  ))}
</ShopList>
</Mobile2>
<Laptop>
    <ButtonContainer>
      <Button
        onClick={() => handleMove(1)}
        disabled={startIndex === shopNames.length - 5}
      >
     <FontAwesomeIcon icon={faAngleRight} /> 
      </Button>
    </ButtonContainer>
    </Laptop>
  </NavigationButtons>
</SidePanelContainer>
  );
};

export default SidePanel;
