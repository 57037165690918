// TermsAndConditions.jsx

import React from "react";
import "./../css/TermsAndConditions.css"; // Include your own stylesheet
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";

const TermsAndConditions = () => {
  const contactEmail = "discountparlour@gmail.com";
  return (
    <div className="terms-and-conditions-container">
      <Header />

      <div className="terms-and-conditions-content">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to Discount Shop! By accessing and using our website, you
          agree to comply with and be bound by the following terms and
          conditions. Please read these terms carefully before using our
          services.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By using our website, you acknowledge that you have read, understood,
          and agree to be bound by these terms. If you do not agree with any
          part of these terms, you may not use our services.
        </p>

        <h2>2. Use of Services</h2>
        <p>
          Discount Shop provides users with access to free coupons and
          discounts. You agree to use the services for their intended purpose
          and in compliance with all applicable laws and regulations.
        </p>

        <h2>3. User Accounts</h2>
        <p>
          In order to access certain features of our website, you may need to
          create a user account. You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account.
        </p>

        <h2>4. Privacy Policy</h2>
        <p>
          Your use of our services is also governed by our Privacy Policy.
          Please review our Privacy Policy to understand how we collect, use,
          and disclose your personal information.
        </p>

        <h2>5. Intellectual Property</h2>
        <p>
          All content on our website, including but not limited to text,
          graphics, logos, images, and software, is the property of Discount
          Shop and is protected by copyright and other intellectual property
          laws. You may not reproduce, distribute, or display any content
          without our express written permission.
        </p>

        <h2>6. Changes to Terms</h2>
        <p>
          Discount Shop reserves the right to update or modify these terms at
          any time without prior notice. Your continued use of our services
          constitutes your acceptance of the modified terms.
        </p>

        <h2>7. Contact Information</h2>
        <p>
          If you have any questions or concerns about our Terms and Conditions,
          please <a href={`mailto:${contactEmail}`}>contact us</a>.
        </p>
      </div>

      <Footer />
      <Rights />
    </div>
  );
};

export default TermsAndConditions;
