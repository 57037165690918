import { React, useState } from "react";
import SearchBar from "./Searchbar";
import Sidebar from "./Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "styled-components";
import { faHome, faTags, faBook, faPhone } from '@fortawesome/free-solid-svg-icons';

import Logomain from "./../objects/logo.png";
const AppHeader = styled.header`
  display: flex;
  flex-direction: row; /* Change to 'row' for horizontal layout */
  align-items: center;
  padding: 0px 0; /* Adjust padding as needed */

  background-color: #383838; /* Replace with your desired background color */
  z-index: 3;
`;

const CenterContent = styled.div`
  display: flex;
  flex-grow: 1; /* Grow to fill available space */
  justify-content: center; /* Align children horizontally in center */
`;

const MobileMenuIcon = styled.div`
  display: none; /* Hide on laptop view */

  @media (max-width: 768px) {
    display: block; /* Show on mobile view */
    cursor: pointer;
    color: black;
  }
`;

const Nav = styled.nav`
  @media (max-width: 768px) {
    display: none; /* Hide on mobile view */
  }
`;
const LogoImage = styled.img`
  width: 10vw; /* Default width */


  @media (max-width: 320px) {
    width: 40vw;
  }

  @media (min-width: 321px) and (max-width: 480px) {
    
    margin-top:1vw;
    width: 30vw;
  }

  @media (min-width: 481px) and (max-width: 768px) {
   
    
    margin-top:0.5vw;
    width: 20vw;
  }

  @media (max-width: 768px) {
    margin-left:1vw;
    margin-top:3.2vw;
    width: 20vw;
   }
 

  @media (min-width: 769px) and (max-width: 1024px) {
    margin-left:1.2vw;
    margin-top:0.7vw;
    width: 15vw;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    
    margin-top:0.5vw;
    width: 12vw;
  }

  @media (min-width: 1200px) {
    margin-left:3vw;
    margin-top:0.5vw;
    width: 11vw;
  }

  @media (min-width: 1300px) {
    margin-top:0.5vw;
    width: 10vw;
  }


  /* For larger screens, you can adjust as needed */
  @media (min-width: 1441px) {
    margin-top:0.2vw;
    width: 10vw;
  }

  @media (min-width: 1601px) {
    margin-top:0.2vw;
    width: 9vw;
  }

  @media (min-width: 1801px) {
    margin-top:0.4vw;
    width: 7vw;
  }
  
  @media (min-width: 1950px) {
    margin-top:0.6vw;
    width: 6vw;
  }

  @media (min-width: 2101px) {
    margin-top:0.4vw;
    width: 5vw;
  }


  @media (min-width: 2501px) {
    margin-top:0.4vw;
    width: 4vw;
  }

  


  @media (max-width: 768px) {
    margin-left: 3vh;
    margin-right: 2vh;
  }
`;


const Ul = styled.ul`
  list-style: none;
  display: flex;
`;
const A = styled.a`
  margin: 0;
  position: relative; /* Position relative for arrow placement */
  display: block;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif; /* Add your preferred font family */
  font-weight: bold; /* Set font weight to bold */

  color: white; /* Replace with your desired text color */
  padding: 10px 15px; /* Increase padding to create a larger clickable area */
  border-radius: 30px; /* Adjust border-radius for more rounded corners */
  transition: box-shadow 0.3s, transform 0.3s; /* Smooth transition */

  &:hover {
    background-color: #ff5e62; /* Change background color on hover */
    color: #fff; /* Change text color on hover */
    box-shadow: 0 0 0 10px rgba(255, 94, 98, 0.5); /* Increase box-shadow size on hover */
    transform: translateY(-5px); /* Move the link up slightly on hover */
  }
`;


const SubUl = styled(Ul)`
  display: none; /* Hide the sub-menu by default */
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff; /* Adjust background color as needed */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  z-index: 1; /* Ensure the sub-menu appears above other elements */

  ${A} {
    margin-right: 0; /* Remove margin for the nested links */
    white-space: nowrap; /* Prevent text from wrapping */
  }

  /* Show the sub-menu when the parent A is hovered */
  ${A}:hover & {
    display: flex;
    flex-direction: column;
  }
`;

const SearchBarContainer = styled.div`
  width: 24vw;
 
  margin-top:0vw;

  @media (max-width: 320px) {
    width: 90vw;
  }


  @media (min-width: 481px) {
    margin-left:2vw;
    margin-top:1.2vw;
    width: 40vw;
  }

  @media (max-width: 768px) {
    margin-left:1vw;
    width: 53vw;
   }
 

 

  @media (min-width: 900px){
    margin-left:2vw;
    margin-top:0.9vw;
    width: 40vw;
  }

  @media (min-width: 1050px){
    margin-left:2vw;
    margin-top:0vw;
    width: 43vw;
  }

  @media (min-width: 1200px) {
    margin-top:0vw;
    width: 33vw;
  }

  @media (min-width: 1300px) {
    margin-top:0vw;
    width: 28vw;
  }


  /* For larger screens, you can adjust as needed */
  @media (min-width: 1441px) {
    margin-top:0vw;
    width: 25vw;
  }

  @media (min-width: 1601px) {
    margin-top:0vw;
    width: 23vw;
  }

  @media (min-width: 1801px) {
    margin-top:0vw;
    width: 21vw;
  }
  
  @media (min-width: 1950px) {
    margin-top:0vw;
    width: 19vw;
  }

  @media (min-width: 2101px) {
    margin-top:0vw;
    width: 17vw;
  }


  @media (min-width: 2501px) {
    margin-top:0vw;
  
    width: 15vw;
  }
  @media (max-width: 768px) {
    margin-left: auto;
    width: 70%;
  }
`;

const Header = () => {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisibility(!isSidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisibility(false);
  };

  return (
    <AppHeader>
      <MobileMenuIcon onClick={toggleSidebar}>☰</MobileMenuIcon>

      <Sidebar isVisible={isSidebarVisible} onClose={closeSidebar} />

      <CenterContent>
      <a href="/">
        <LogoImage src={Logomain} alt="Logo" />
      </a>

        <Nav>
          <Ul>
            <A href="/"><FontAwesomeIcon icon={faHome} /> Home</A>
            <A href="/#/coupons " className="tnb-nav-btn w3-bar-item w3-button barex bar-item-hover w3-padding-16 ga-top ga-top-exc-and-quz"><FontAwesomeIcon icon={faTags} /> Coupons</A>
            <A href="/#/Learn"><FontAwesomeIcon icon={faBook} /> Learn</A>
            <A href="/#/ContactUs"><FontAwesomeIcon icon={faPhone} /> Contact</A>
          </Ul>
        </Nav>
        <SearchBarContainer>
          <SearchBar />
        </SearchBarContainer>
      </CenterContent>
    </AppHeader>
  );
};

export default Header;
