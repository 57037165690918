import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

// Styled Components
const MainContainer = styled.div`
  background-color: #e9ecef;
  min-height: 100vh;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 40px;
  font-size: 3rem;
  color: #343a40;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Description = styled.div`
  text-align: justify;
  font-size: 1.2rem;
  color: #495057;
  line-height: 1.6;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  p {
    margin-bottom: 15px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const ReadMoreButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const JustReleased = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://discountparlour.com/api/get/${slug}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <MainContainer>
      <Header />
      <div className="container">
        {data && (
          <>
            <Heading>{data.heading}</Heading>
            <Image src={data.image} alt={data.heading} />
            <Description dangerouslySetInnerHTML={{ __html: data.description }} />
            <ButtonContainer>
              <ReadMoreButton href={`/blog/${slug}`}>Read More</ReadMoreButton>
            </ButtonContainer>
          </>
        )}
      </div>
      <Footer />
    </MainContainer>
  );
};

export default JustReleased;
