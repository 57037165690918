// CouponsPage.jsx
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";
import CouponList from "./../Components/CouponList";

const globalStyles = css`
  body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
    background-color: #f4f4f4;
  }

  .container-shop {
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-width: 60%;
    align-items: center;

    @media (max-width: 600px) {
      margin: 0;
      padding: 0;
      border: none;
      box-shadow: none;
      background-color: transparent;
      max-width: none;
      align-items: stretch;
    }
  }

  .shop-name {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  .category-section {
    margin-bottom: 20px;
  }

  .category-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }

  .coupon-section {
    display: flex;
    flex-wrap: wrap;
  }

  .coupon-card {
    width: calc(40% - 20px);
    margin: 0 10px 20px;
    padding: 15px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
  }

  .coupon-image {
    max-width: 70%;
    height: auto;
    margin-bottom: 10px;
  }

  .coupon button {
    background-color: white;
    color: #ff3d44;

    &:hover {
      background-color: #ff3d44;
      color: white;
    }
  }

  .coupon-name {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .coupon-details {
    font-size: 14px;
    color: #777;
  }

  .ends-in-timer {
    color: red;
  }

  .off-percentage {
    color: green;
  }

  .coupon {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: calc(32% - 20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: transform 0.3s ease-in-out;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 2vh;
  }
`;

const CouponsPageContainer = styled.div`
  ${globalStyles}
`;

const ContentContainer = styled.div`
  ${globalStyles}
`;

const TwoSectionsContainer = styled.div`
  ${globalStyles}
`;

const CouponListContainer = styled.main`
  ${globalStyles}
`;

const CouponListTitle = styled.h2`
  ${globalStyles}
`;

const CouponsPage = () => {
  const [coupons, setCoupons] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetch("https://discountparlour.com//api/vouchers")
      .then((response) => response.json())
      .then((data) => setCoupons(data))
      .catch((error) => console.error("Error fetching data:", error));

    const delay = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(delay);
  }, []);

  return (
    <CouponsPageContainer className={`coupons-page ${loaded ? "loaded" : ""}`}>
      <Header />
      <ContentContainer className="container-shop">
        <TwoSectionsContainer className="two-sections">
          <CouponListContainer className="coupon-list">
            <CouponListTitle>Get Free Coupons and Deals</CouponListTitle>
            <CouponList coupons={coupons} />
          </CouponListContainer>
        </TwoSectionsContainer>
      </ContentContainer>
      <Footer />
      <Rights />
    </CouponsPageContainer>
  );
};

export default CouponsPage;
