// CouponList.jsx
import React, { useState } from "react";
import styled from "styled-components";
import GetOffer from "./GetOffer";

const CouponSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
`;

const Coupon = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: calc(28% - 20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease-in-out;
  margin: 2vh;

  img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    
  }

  .coupon-details {
    padding: 15px;
    text-align: center;

    .coupon-name-and-discount {
      h3 {
        font-size: 1.2rem;
        margin-bottom: 5px;
      }

      p {
        color: red;
        margin: 0;
        align-self: center;
      }
    }

    .coupon-description {
      margin-bottom: 10px;
      max-height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .get-offer-btn {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      border: none;
      padding: 10px;
      width: 80%;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: #ff3d44;
        color: white;
      }
    }
  }

  .coupon-content button {
    background-color: white;
    color: #ff3d44;

    &:hover {
      background-color: #ff3d44;
      color: white;
    }
  }

  .coupon-content p {
    color: red;
  }
  .coupon-content h3 {
    color: black;
  }

  @media (max-width: 960px) {
    width: calc(50% - 20px);

    &:nth-child(2n + 1) {
      margin-right: 20px;
    }

    .get-offer-btn {
      width: 90%;
    }
  }

  @media (max-width: 600px) {
    width: calc(100% - 20px);
    margin-right: 0;
  }
`;

const CouponList = ({ coupons }) => {
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const openGetOfferModal = (coupon) => {
    setSelectedCoupon(coupon);
  };

  const closeGetOfferModal = () => {
    setSelectedCoupon(null);
  };

  const renderCoupons = () => {
    if (!Array.isArray(coupons)) {
      console.error("Coupons data is not an array:", coupons);
      return null;
    }

    return coupons.map((coupon, index) => (
      <Coupon key={coupon.id}>
        <div className="coupon-details">
          <div className="coupon-image">
            {coupon.image_path && (
              <img
                
              src={`https://discountparlour.com/${coupon.image_path}`}
                alt="Coupon Image"
              />
            )}
          </div>
          <div className="coupon-content">
            <h3>{coupon.name}</h3>
            <span>{coupon.shop_name}</span>
            <p>{coupon.off_percentage}% off</p>
            <div className="coupon-description">{coupon.description}</div>
            <button onClick={() => openGetOfferModal(coupon)}>Get Offer</button>
          </div>
        </div>
      </Coupon>
    ));
  };

  return (
    <CouponSection>
      {renderCoupons()}
      {selectedCoupon && (
        <GetOffer couponDetails={selectedCoupon} onClose={closeGetOfferModal} />
      )}
    </CouponSection>
  );
};

export default CouponList;
