import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

import TwitterLogo from "./../objects/twitter.png";
import InstagramLogo from "./../objects/instagram.png";
import FacebookLogo from "./../objects/facebook.png";
import WebsiteLogo from "./../objects/logo.png";

const ResponsiveContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;



const StyledFooter = styled.footer`
  background-color: #383838; ;
  padding: 20px;
  margin-left:-20px;

`;
const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }

  @media (max-width: 767px) {
    align-items: flex-start; /* Align left for mobile */
  }
  @media (max-width: 767px) {
   
    margin-left:20px;
  }
`;


const FooterNavigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
  margin-left:10px;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-direction: row;
  }

  
`;


const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const LogoImage = styled.img`
  width: 100%;
  max-width: 150px;
  height: auto;
  
`;

const Description = styled.p`
  max-width: 400px;
  color: white;
  text-align: left;
`;

const SectionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const SectionListItem = styled.li`
  margin-bottom: 5px;
`;

const SectionListLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const FooterSection = styled.div`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 70px;
  }
  

`;

const SectionTitle = styled.h5`
  font-size: 1rem;
  margin-bottom: 10px;
  
  color: white;
`;

const SocialNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
`;

const NewsletterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const NewsletterTitle = styled.h4`
  margin-bottom: 10px;
  color: black;
`;

const NewsletterInput = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
`;

const NewsletterButton = styled.button`
  background-color: #ff5e62;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ff3d44;
  }

  &.subscribed {
    background-color: #28a745;
    cursor: not-allowed;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  
  align-items: flex-end;
  flex-direction: row;
`;

const SocialIconLink = styled.a`
  margin: 10px;
`;

const SocialIconImage = styled.img`

  width: 30px;
  filter: invert(1);
  height: 30px;
`;

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscribe = async () => {
    console.log("Ds");
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        console.error("Invalid email format");
        toast.error("Invalid email format");
        return;
      }

      const response = await fetch(
        "https://discountparlour.com/api/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Make sure this header is set
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        // Handle successful subscription
        setIsSubscribed(true);
        setEmail("");
        toast.success("Subscription successful");
      } else {
        // Handle subscription error
        const errorData = await response.json(); // Parse the error response
        console.log("aDs", errorData); // Log the error data for inspection
        const errorMessage = errorData.error || "Subscription failed"; // Get error message from response or use a default message
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error during subscription");
    }
  };

  return (
    <StyledFooter>
      <FooterContent>
        <LogoContainer>
          <a href="/">
            <LogoImage src={WebsiteLogo} alt="LOGO" />
          </a>
          <Description>
            Your go-to destination for exclusive<br></br> savings – unlocking a world of
            free <br></br>coupons for a seamless shopping <br></br>experience
          </Description>
        </LogoContainer>

        <FooterNavigation>
          <FooterSection>
            <SectionTitle>Company</SectionTitle>
            <SectionList>
              <SectionListItem>
                <SectionListLink href="/#/AboutUs">About Us</SectionListLink>
              </SectionListItem>
              <SectionListItem>
                <SectionListLink href="/#/Careers">Careers</SectionListLink>
              </SectionListItem>
              <SectionListItem>
                <SectionListLink href="/#/Terms&Conditions">
                  Terms & Conditions
                </SectionListLink>
              </SectionListItem>
              <SectionListItem>
                <SectionListLink href="/#/ContactUs">
                  Contact Us
                </SectionListLink>
              </SectionListItem>
              <SectionListItem>
                <SectionListLink href="/#/PrivacyPolicy">
                  Privacy Policy
                </SectionListLink>
              </SectionListItem>
            </SectionList>
          </FooterSection>

          <FooterSection>
            <SectionTitle>Special Pages</SectionTitle>
            <SectionList>
              <SectionListItem>
                <SectionListLink href="/#/NoDealsYet">
                  Halloween Day Sale
                </SectionListLink>
              </SectionListItem>
              <SectionListItem>
                <SectionListLink href="/#/NoDealsYet">
                  Black Friday Deals
                </SectionListLink>
              </SectionListItem>
              <SectionListItem>
                <SectionListLink href="/#/NoDealsYet">
                  Thanksgiving Day Sale
                </SectionListLink>
              </SectionListItem>
              <SectionListItem>
                <SectionListLink href="/#/NoDealsYet">
                  Cyber Monday Deals
                </SectionListLink>
              </SectionListItem>
              <SectionListItem>
                <SectionListLink href="/#/NoDealsYet">
                  Pet Care Deals
                </SectionListLink>
              </SectionListItem>
            </SectionList>
          </FooterSection>
        </FooterNavigation>

        <SocialNewsContainer>
          <NewsletterBox>
            <NewsletterTitle>
              Sign up for the latest updates and sales
            </NewsletterTitle>
            <NewsletterInput
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            <NewsletterButton
              onClick={handleSubscribe}
              disabled={isSubscribed}
              className={isSubscribed ? "subscribed" : ""}
            >
              {isSubscribed ? "Subscribed" : "Subscribe"}
            </NewsletterButton>
          </NewsletterBox>

          <SocialIcons>
            <SocialIconLink
              href="https://www.facebook.com/profile.php?id=61556267575197"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImage src={FacebookLogo} alt="Facebook" />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.instagram.com/discount_parlour/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImage src={InstagramLogo} alt="Instagram" />
            </SocialIconLink>
            <SocialIconLink
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImage src={TwitterLogo} alt="Twitter" />
            </SocialIconLink>
          </SocialIcons>
        </SocialNewsContainer>
      </FooterContent>
      <ToastContainer />
    </StyledFooter>
  );
};

export default Footer;
