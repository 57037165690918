import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const SidebarWrapper = styled.div`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  align-items: left;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background-color: #282a35;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 2;
  padding-top: 60px;
`;

const CloseButton = styled.div`
  position: absolute;
  color: red;
  top: 20px;
  right: 10px;
  cursor: pointer;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  padding-top: 2%;
  margin: 0;
  text-align: center;

  & > li {
    padding: 0;
    margin: 0;
  }
`;

const A = styled.a`
  display: block;
  text-decoration: none;
  color: white;
  background-color: #282a35;
  padding: 15px;
  padding-top: 25px;
  padding-left: 10%;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
  width: 80%;

  &:hover {
    background-color: #ff5e62;
  }
`;

const ExpandableList = styled.li`
  border-bottom: 1px solid #ddd;
  padding: 0;
  text-align: left;
`;

const ExpandableListItem = styled.li`
  padding: 10px;
  cursor: pointer;
`;

const Sidebar = ({ isVisible, onClose, children }) => {
  const [expanded, setExpanded] = useState(false);
  const [expandedShop, setExpandedShop] = useState(null);
  const [shopNames, setShopNames] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  const handleShopSelect = (selectedShop) => {
    navigate(`/ShopCoupons/${selectedShop.shop_name}`);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch("https://discountparlour.com//api/shops", { signal })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error("API error:", data.error);
        } else {
          console.log("API response:", data);
          setShopNames(Array.isArray(data) ? data : [data]);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Request was aborted");
        } else {
          console.error("Error fetching data:", error);
        }
      })
      .finally(() => {
        setLoaded(true);
      });

    return () => controller.abort();
  }, []);

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  const handleShopRowClick = (shopName) => {
    setExpandedShop((prevShop) => (prevShop === shopName ? null : shopName));
  };

  return (
    <SidebarWrapper isVisible={isVisible}>
      <CloseButton onClick={onClose}>☰</CloseButton>
      <Ul>
        <li>
          <A href="/">Home</A>
        </li>
        <li>
          <A href="/#/coupons">Coupons</A>
        </li>
        <li>
          <A onClick={handleExpandToggle}>
            Shops {expanded === true ? " ▲" : " ▼"}
          </A>
          {expanded && (
            <ExpandableList>
              {shopNames.map((shop) => (
                <ExpandableListItem
                  key={shop.shop_name}
                  onClick={() => handleShopRowClick(shop.shop_name)}
                >
                  <A
                    onClick={onClose}
                    href={`/#/ShopCoupons/${shop.shop_name}`}
                  >
                    {shop.shop_name}
                  </A>
                </ExpandableListItem>
              ))}
            </ExpandableList>
          )}
        </li>
        <li>
          <A href="/#/underConstruction">Most Sales</A>
        </li>
        <li>
          <A href="/#/underConstruction">Big Sales</A>
        </li>
        <li>
          <A href="/#/Learn">Learn</A>
        </li>
        <li>
          <A href="/#/AboutUs">About Us</A>
        </li>
        <li>
          <A href="/#/ContactUs">Contact</A>
        </li>
      </Ul>
    </SidebarWrapper>
  );
};

export default Sidebar;
