import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

// Styled components
const SearchContainer = styled.div`
  position: relative;
  display: flex;

  align-items: center;
  width: 80%;
  @media (max-width: 768px) {
    width: 90%;
  }

 
`;

const Input = styled.input`
  flex: 1;
  padding: 14px; /* Increased padding for a bigger height */
  margin-right: 2%;
  border-radius: 20px; /* Removed border-radius */
  border: 1px solid #ccc; /* Added border for clarity */
  position: relative;
  width: 60%;
  transform: translateY(20%);
  height: 46px; /* Adjusted height */
  outline: none; /* Remove default outline on focus */

  @media (max-width: 768px) {
    height:10vw;
  }
  
`;


const SearchIcon = styled.span`
  position: absolute;
  right: 1.4vw;
  top: 55%;
  transform: translateY(-50%);
  color: black;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 768px) {
    right: 3vw;
  }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: calc(67%);
  left: -1%;
  transform: translateX(-6%);
  width: 97%;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-top: none;
  border-radius:6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  z-index: 1;

  @media (max-width: 768px) {
    transform: translateX(-10%);
  }
`;

const ListItem = styled.li`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #333;

  &:hover {
    background-color: #eaeaea;
  }
`;

const ShopImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const Button = styled.button`
  padding: 10px 16px;
  background-color: #ff5e62;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #ff3d44;
  }
`;

const SearchBar = () => {
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const maxShopNameLength = window.innerWidth <= 768 ? 10 : 20;
  useEffect(() => {
    // Fetch search suggestions from the backend based on the searchInput
    if (searchInput.trim() === "") {
      // Reset suggestions if the input is empty
      setSuggestions([]);
    } else {
      fetch(`https://discountparlour.com/api/search?query=${searchInput}`)
        .then((response) => response.json())
        .then((data) => setSuggestions(data))
        .catch((error) => {
          console.error("Error fetching coupons:", error.message);
        });
    }
  }, [searchInput]);

  const handleShopSelect = (selectedShop) => {
    // Use navigate to navigate to the ShopCoupons page with the selected shop name
    navigate(`/ShopCoupons/${selectedShop}`);
  };

  const truncateShopName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  return (
    <SearchContainer>
      <Input
        type="text"
        placeholder="Search Brands..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      {suggestions.length > 0 && (
        <SuggestionsList>
          {suggestions.map((suggestion) => (
            <ListItem
              key={suggestion.shop_name}
              onClick={() => handleShopSelect(suggestion.shop_name)}
            >
              {suggestion.shop_image_path && (
                <ShopImage
                src={`https://discountparlour.com/${suggestion.shop_image_path}`}
                  alt="Shop Image"
                />
              )}
               <span title={suggestion.shop_name}>
                {truncateShopName(suggestion.shop_name, maxShopNameLength)} (
                {suggestion.offerCount} offers)
              </span>
            </ListItem>
          ))}
        </SuggestionsList>
      )}
      <SearchIcon onClick={() => alert("Search button clicked")}>
        <CiSearch />
      </SearchIcon>
    </SearchContainer>
  );
};

export default SearchBar;
