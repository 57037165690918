// Update your ContactUs.jsx

import React from "react";
import "./../css/ContactUs.css";
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";

const ContactUs = () => {
  const email = "discountparlour@gmail.com";

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="contact-us-container">
      <Header />

      <div className="contact-content">
        <h1>Contact Us</h1>
        <p>
          At Discount Shop, we deeply value our users and understand that every
          interaction is an opportunity to connect, assist, and build lasting
          relationships. Our hearts go out to you, and we want you to know that
          we are here for you every step of the way.
        </p>
        <p>
          If you have any inquiries, concerns, or simply want to share your
          thoughts, don't hesitate to reach out. We believe in the power of
          savings, and our platform is dedicated to providing you with free
          coupons for a seamless and delightful shopping experience. Beyond just
          deals, we strive to create a community where your voice is heard and
          your needs are met.
        </p>
        <p>
          Our team is committed to addressing your queries promptly and ensuring
          that you feel supported. Feel free to contact us via email, and we'll
          do our utmost to assist you. Thank you for being a part of our
          community, and we look forward to making your experience with us
          extraordinary.
        </p>
        <button className="contact-button" onClick={handleEmailClick}>
          Contact Us
        </button>
      </div>

      <Footer />
      <Rights />
    </div>
  );
};

export default ContactUs;
