import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";
import SidePanel from "./../Components/SidePanel";
import BrandSection from "./../Components/BrandSection";
import GetOffer from "./../Components/GetOffer";

import { useNavigate } from "react-router-dom";
import "./../css/GetOffer.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTags, faBook, faPhone } from '@fortawesome/free-solid-svg-icons';

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TitlePageContainer = styled.div`
  display: flex;
  background-color: #f8f9f9;
  flex-direction: column;
  min-height: 100vh;
`;

const AnimationHeader = styled.div`
  animation: ${slideIn} 1.5s ease-out forwards;
  position: relative;
  padding:3px;
  background-color: #595959;
  color: white;
  z-index: 1;
`;

const AnimatedMessage = styled.p`
  font-family: 'Pacifico', cursive;
  transform: translateY(-100%);
  opacity: 0;
  animation: ${slideIn} 1.5s ease-out forwards;
  text-align: center;
  overflow: hidden;
  height: 30px;
  margin: 0;
  padding: 0;
  color: white;

  @media (max-width: 767px) {
      
    font-size: 10px;
    height: 15px;
   }
 
 
 
 
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 20px;
`;

const TwoSectionsContainer = styled.div`
  display: flex;
  flex: 1;
`;

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  text-align: center;
  border-radius: 20px;
`;


const CouponTitle = styled.div`
  width: calc(100% );
  margin-top: 10px;
  border: none;
  background-color: white;
  border-radius: 8px;
  

  @media (max-width: 767px) {
    width: 92%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const CouponContent = styled.div`
  display: flex;
  @media (max-width: 767px) {
    
    flex-direction: column;
  }
  width: 100%;

`;

const CouponImage = styled.img`
  width: 300px;
  height: 150px; /* Set a fixed height */
  border-radius: 8px 8px 0 0;

  @media (max-width: 767px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    
  width: 180px;
  height: 100px;
    margin-top:10px;
    border: 0.01px solid rgba(163, 163, 163, 0.2); 
    border-radius: 8px;
  }
`;

const CouponDetailsTitle = styled.div`
  padding: 10px;
  background-color: white;
  border-radius: 0 0 8px 8px;

  .endtime {
    font-size: 12px;
    color: red;

    @media (max-width: 1030px) {
      font-size: 11px;
    }
  }

  
  @media (max-width: 767px) {
    margin-left:10px;
      }

  .coupon-description {
    white-space: pre-line; /* This property ensures that the description wraps to the next line when it exceeds the available width */
    /* You can add additional styling here as needed */
    width:280px;
    @media (min-width: 1200px) {
      width:350px;
    }

    @media (max-width: 1030px) {
      width:200px;
      font-size: 11px;
    }

    
  
  }
  
  @media (max-width: 767px) {
    border-radius: 0;
  }
`;

const CouponNameAndDiscount = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const CouponName = styled.h4`
  margin: 0;
  margin-top: 5px;
  text-align: left;
  @media (max-width: 1030px) {
    font-size: 13px;
  }
`;

const CouponDiscount = styled.span`
  color: green;
  margin-top: 7px;
  text-align: right;
  @media (max-width: 1030px) {
    font-size: 10px;
  }
`;

const Title = styled.h2`
  color: #333;
  font-size: 1.5rem;
   margin-top:50px;
  margin-bottom: 0px;
  font-weight: 300; /* Adjust the font weight to make it thinner */
  font-family: Arial, sans-serif; /* Choose an appropriate font family */
  
  @media (min-width: 1200px) {

    margin-left:8vw;
  }
  
  @media (max-width: 767px) {
    font-size:19px;
    
    margin-top:8px;
    
    
  }
  @media (min-width: 1300px) {
    
  
    margin-left:14vw;
 
  }
  @media (max-width: 1030px) {
    
    margin-left:5.3vw;
  }


  /* For larger screens, you can adjust as needed */
  @media (min-width: 1441px) {
    
    margin-left:16.7vw;

  }

  @media (min-width: 1601px) {
    
    margin-left:19.35vw;

  }

  @media (min-width: 1801px) {
    margin-left:24.1vw;
  }
  
  @media (min-width: 1950px) {

    margin-left:26.4vw;
  }


  @media (min-width: 2101px) {
    
    margin-left:29.1vw;
  
  }
  @media (min-width: 2501px) {
  
    margin-left:33.6vw;
    }
`;
const CouponButtonHover = styled.button`

  background-color: white;
  color: white;
  padding: 12px 32px;
  width:auto;
  border: none;
  height:10%;
  background-color:#ff9900;
  border-radius: 4px;
  margin-left: auto; 
  margin-right: 10px;
  cursor: pointer;
  margin-top: 40px; /* Aligns the button to the center vertically */
  margin-bottom: auto; 
  font-size: 15px;

  &:hover {
    background-color: #ff3d44;
    color: white;
  }

  @media (max-width: 767px) {
    margin-top: -10px;
    margin-left:20px;
    padding: 16px 24px;
    height:20%;
    font-size: 13px;
    
  margin-right: 0px;
    width:40%; /* Set width to 100% for mobile */
    margin-bottom:15px;
  }


      

  @media (min-width: 1200px) {

    font-size: 15px;
  }

  @media (min-width: 1300px) {
    
    font-size: 15px;

 
  }


  /* For larger screens, you can adjust as needed */
  @media (min-width: 1441px) {
    
    font-size: 15px;

  }

  @media (min-width: 1601px) {
    
    font-size: 15px;
  }

  @media (min-width: 1801px) {
    
    font-size: 15px;
  }
  
  @media (min-width: 1950px) {
      
    font-size: 15px;
  }


  @media (min-width: 2101px) {
    width:23%;
    
    font-size: 15px;
  }
  @media (min-width: 2501px) {
    width:25%;
    }
`;

const Mobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin: 0;
    padding: 0;
  }
`;

const BrandSectionContainer = styled(BrandSection)`
  flex: 1;
  width: 80%;
  padding: 0px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const SidePanelContainer = styled(SidePanel)`
  width: 20%;
  background-color: white;
  padding: 20px;
  color: black;
  width: fit-content;
  position: fixed;
  top: 50px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Laptop = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const PopularStoresContainer = styled.div`
flex: 0 0 20%;
margin-left:-40px;
  flex:1;
  @media (max-width: 767px) {
    
    width: 100%;
  }

`;

const PopularStoresHeading = styled.h3`
  color: #333;
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    
  margin-left:35px;
  
  }
`;

const CouponSectionTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 20px;


   
  @media (min-width: 1200px) {
    width: 87%;
    margin-left:70px;
  }

  @media (min-width: 1300px) {
    width: 84%;
    margin-left:98px;

 
  }

  @media (max-width: 1030px) {
    width: 90%;
    margin-left:27px;
  }


  /* For larger screens, you can adjust as needed */
  @media (min-width: 1441px) {
    width: 84%;
    margin-left:94px;

  }

  @media (min-width: 1601px) {
    width: 86%;
    margin-left:80px;
  }

  @media (min-width: 1801px) {
    width: 84%;
    margin-left:100px;
  }
  
  @media (min-width: 1950px) {
    width: 81%;
    margin-left:120px;
  }


  @media (min-width: 2101px) {
    width: 81%;
    margin-left:120px;
  }
  @media (min-width: 2501px) {
    width: 84%;
  margin-left:100px;
    }
  
`;


const ShopListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  width: 62%; /* Set the width to 20% of its container */
  @media (max-width: 767px) {
    
    margin-left:35px;
    width: 85%;
    }
  
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid white;
  &:hover {
    border: 1px solid yellow;
  }
  @media (min-width: 1200px) {
    width: 43%; 
  }

  @media (min-width: 1300px) {
    width: 43%; 

  }


  /* For larger screens, you can adjust as needed */
  @media (min-width: 1441px) {
    width: 45%; 

  }

  @media (min-width: 1601px) {
    
    width: 48%; 

  }

  @media (min-width: 1801px) {
    width: 48%; 
  }
  
  @media (min-width: 1950px) {
    width: 46%; 
  }


  @media (min-width: 2101px) {
    width: 51%; 
  }
  @media (min-width: 2501px) {
    width: 62%; 
    }
 
 
 
 

`;

const ShopImage = styled.img`
width: 120px;
height: 10px;
  margin-bottom: 5px;

  
  @media (max-width: 768px) {
  
   }
   @media (max-width: 767px) {
    
    width: 50px;
    height: 50px
  }
 
 
   @media (min-width: 900px) {
    width: 152px;
    height: 120px
   }
 
 
   @media (min-width: 1200px) {
    width: 143px;
    height: 120px
   }
 
   @media (min-width: 1300px) {
    width: 140px;
    height: 124px;
   }
 
 
   /* For larger screens, you can adjust as needed */
   @media (min-width: 1441px) {
    width: 140px;
    height: 124px;
   
 
   }
 
   @media (min-width: 1601px) {
    width: 166px;
    height: 124px;

   }
 
   @media (min-width: 1801px) {
    width: 163px;
    height: 124px;
   }
   
   @media (min-width: 1950px) {
    width: 157px;
    height: 124px;
   }
 
 
   @media (min-width: 2101px) {
    width: 158px;
    height: 120px;
   
   }
   @media (min-width: 2501px) {
    width: 169px;
    height: 140px;
   
     }
`;

const CouponAndPopularStoresContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    
    flex-direction: column;
    margin-left: -34px;
    margin-top:-10px;
  }
  justify-content: space-between; // Adjust as needed
`;

const CouponContainer = styled.div`
  flex: 1;
  margin-right: 10px; // Adjust margin as needed
  margin-left:-3%;
  flex: 0 0 80%;

    @media (max-width: 1741px) {
    
      margin-left:-6%;

  }
`;

const TitlePage = () => {
  const [coupons, setCoupons] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [shopNames, setShopNames] = useState([]);
  const navigate = useNavigate();


  const shops = shopNames.slice(0, 4);
  useEffect(() => {
    fetch("https://discountparlour.com//api/featured-vouchers")
      .then((response) => response.json())
      .then((data) => setCoupons(data))
      .catch((error) => console.error("Error fetching data:", error));

    const delay = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(delay);
  }, []);

  const handleShopSelect = (selectedShop) => {
    navigate(`/ShopCoupons/${selectedShop.shop_name}`);
  };

  useEffect(() => {
    fetch("https://discountparlour.com/api/shops")
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error("API error:", data.error);
        } else {
          console.log("API response:", data);
          setShopNames(Array.isArray(data) ? data : [data]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleGetOfferClick = (coupon) => {
    setSelectedCoupon(coupon);
  };

  const handleCloseGetOffer = () => {
    setSelectedCoupon(null);
  };

  const exploreCoupons = () => {
    // Use the navigate function to move to the 'coupons' route
    navigate("/coupons");
  };

  return (
    <TitlePageContainer className={`title-page ${loaded ? "loaded" : ""}`}>
      <AnimationHeader>
        {loaded && (
          <AnimatedMessage>
            Exclusive Coupons Available! Grab them now. 🎁
          </AnimatedMessage>
        )}
      </AnimationHeader>
      <Header />
      <ContentContainer>
        <TwoSectionsContainer>
          <BrandSectionContainer />
        </TwoSectionsContainer>
      </ContentContainer>
      <SidePanelContainer />


      <Title>Latest Coupon Codes and Deals</Title>
      <MainContainer>
        <CouponAndPopularStoresContainer>
          <CouponContainer>
            <CouponSectionTitle>
              {coupons.map((coupon) => (
                <CouponTitle
                  key={coupon.id}
                  className={`coupon-title${coupons.length === 1 ? " single" : ""}`}
                >
                  <CouponContent>
                    {coupon.image_path && (
                      <CouponImage
                        src={`https://discountparlour.com/${coupon.image_path}`}
                        alt="Coupon Image"
                      />
                    )}
                    <CouponDetailsTitle>
                      <CouponNameAndDiscount>
                        <CouponName>{coupon.name}</CouponName>
                      </CouponNameAndDiscount>
                      <p className="endtime">Ends in {coupon.ends_in_timer} day &nbsp;
                        <CouponDiscount>
                          {coupon.off_percentage}% off
                        </CouponDiscount></p>


                      <p className="coupon-description">{coupon.description}</p>

                    </CouponDetailsTitle>
                    <CouponButtonHover
                      onClick={() => handleGetOfferClick(coupon)}
                    >

                      Get Offer  <FontAwesomeIcon icon={faTags} />
                    </CouponButtonHover>

                  </CouponContent>
                </CouponTitle>
              ))}


            </CouponSectionTitle>
          </CouponContainer>
          <PopularStoresContainer>
            <PopularStoresHeading>Popular Stores</PopularStoresHeading>
            {shops.slice(0, 4).map((shop) => (
              <ShopListItem key={shop.id} onClick={() => handleShopSelect(shop)}>
                {shop.shop_image_path && (
                  <ShopImage
                    src={`https://discountparlour.com/${shop.shop_image_path}`}
                    alt="Shop Image"
                  />
                )}
              </ShopListItem>
            ))}
          </PopularStoresContainer>
        </CouponAndPopularStoresContainer>
        <div className="explore-container">
          <button className="explore-button" onClick={exploreCoupons}>
            Explore Coupons
          </button>
        </div>
      </MainContainer>
      {selectedCoupon && (
        <GetOffer
          couponDetails={selectedCoupon}
          onClose={handleCloseGetOffer}
        />
      )}

      <Footer />
      <Rights />
    </TitlePageContainer>
  );
};

export default TitlePage;
