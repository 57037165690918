import React from "react";
import "./../css/CompanyRights.css";
const CompanyRights = () => {
  return (
    <div className="rights">
      <p>&copy; 2024 Your Company Name. All rights reserved</p>
    </div>
  );
};

export default CompanyRights;
