import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react"; // Import TinyMCE
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #343a40;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const AddJRM = () => {
  const [slug, setSlug] = useState("");
  const [heading, setHeading] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      slug,
      heading,
      image,
      description,
    };

    try {
      const response = await fetch("https://discountparlour.com/api/addJRM", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json(); // Parse the JSON response

      if (response.ok) {
        setMessage("JRM added successfully!");
        // Clear input fields
        setSlug("");
        setHeading("");
        setImage("");
        setDescription("");
      } else {
        // Display the error message returned from the server
        setMessage(result.error || "Failed to add JRM. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An unexpected error occurred. Please try again.");
    }
  };
  return (
    <Container>
      <Title>Add New JRM</Title>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Slug:</label>
          <input
            type="text"
            className="form-control"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            placeholder="Enter URL-friendly slug"
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Heading:</label>
          <input
            type="text"
            className="form-control"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            placeholder="Enter the heading/title"
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Image URL:</label>
          <input
            type="text"
            className="form-control"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            placeholder="Enter image URL"
            required
          />
        </div>

        <div className="mb-4">
          <label className="form-label">Description:</label>
          <Editor
            apiKey="88ajl7cuhzzi0wa2dulizl34yc8ll9heqp4wsy8l16dkr71i" // Optional: Add your TinyMCE API key here
            value={description}
            onEditorChange={setDescription}
            init={{
              height: 300,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </div>

        <SubmitButton type="submit">Add JRM</SubmitButton>
      </form>

      {message && (
        <div className="text-center mt-3">
          <p className={`alert ${message.includes("successfully") ? "alert-success" : "alert-danger"}`}>
            {message}
          </p>
        </div>
      )}
    </Container>
  );
};

export default AddJRM;
