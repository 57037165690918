import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";
import SidePanel from "./../Components/SidePanel";
import { useParams } from "react-router-dom";
import CouponList from "./../Components/CouponList";
import "./../css/App.css";
const globalStyles = css`
  .container-shop {
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-width: 60%;
    align-items: center;

    @media (max-width: 600px) {
      margin: 0;
      padding: 0;
      
      border: none;
      box-shadow: none;
      background-color: transparent;
      max-width: none;
      align-items: stretch;
    }
  }

  @media (max-width: 768px) {
    
    .container-shop {
      
      max-width: 100%; /* Adjust max-width for mobile screens */
    }
    
    .shop-name {
      font-size: 20px; /* Decrease font size for smaller screens */
    }

    /* Adjust other styles as needed for smaller screens */
  }

  
  .shop-name {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  .category-section {
    margin-bottom: 20px;
  }

  .category-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }

  .coupon-section {
    display: flex;
    flex-wrap: wrap;
  }

  .coupon-card {
    width: calc(40% - 20px);
    margin: 0 10px 20px;
    padding: 15px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
  }

  .coupon-image {
    max-width: 70%;
    height: auto;
    margin-bottom: 10px;
  }

  .coupon button {
    background-color: white;
    color: #ff3d44;

    &:hover {
      background-color: #ff3d44;
      color: white;
    }
  }

  .coupon-name {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .coupon-details {
    font-size: 14px;
    color: #777;
  }

  .ends-in-timer {
    color: red;
  }

  .off-percentage {
    color: green;
  }

  .coupon {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: calc(32% - 20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: transform 0.3s ease-in-out;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 2vh;
  }
`;

const SearchBar = styled.input`
  margin-left: auto; /* Move the search bar to the right */
  margin-top: 20px;
  padding: 5px 10px;
  width: 200px; /* Adjust width as needed */
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CouponListContainer = styled.main`
  ${globalStyles}
`;

const CouponListTitle = styled.h2`
  ${globalStyles}
`;
const CouponsPageContainer = styled.div`
  ${globalStyles}
`;

const ContentContainer = styled.div`
  ${globalStyles}
`;

const ContentContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  
  align-items: center;
  margin-top: 10px; /* Adjust the margin as needed */
  background-color: #f7f7f7; /* Background color */
  border-radius: 10px; /* Border radius for rounded corners */
  padding: 20px; /* Padding to create space inside the container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for depth */

`;

const Mobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;

    background-color: white;
    border-radius: 10px; /* Adjust the border radius as needed */
    margin: 10px; /* Adjust the margin from all sides */
   
  }
`;

const Laptop = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const ShopInfoContainer = styled.div`
  ${globalStyles}
`;

const ShopImage = styled.img`
  width: 20%;
  max-height: 100%;
`;

const FireIcon = styled.i`
  /* Add styling for the fire icon */
`;


const ShopInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  
  @media (max-width: 768px) {
   
    margin-left: -34px;
  }
`;

const ShopLink = styled.a`

  
  color:#405eab;
  text-decoration: underline;
  font-size: 14px;
`;
const CouponInfo = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 20px;
   @media (max-width: 600px) {
     
    }
`;


const MobCouponInfo = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid black;
  border-radius: 50px;
  margin-right:5vw;
  padding: 10px 20px;
   @media (max-width: 600px) {
     
    }
    
    
  @media (max-width: 768px) {

    margin-left:-18px;
   
  }
`;

const CouponPromocode = styled.span`
  margin-right: 10px;
`;

const CopyButton = styled.button`
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: auto;
`;

const CopiedButton = styled.button`
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: auto;
`;
const ShopName = styled.h1`
  font-size: 36px;
  margin-bottom: 1px;
`;

const MobShopName = styled.h1`
  font-size: 16px;
  margin-bottom: 1px;
`;


const MobContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left:20px;
  margin-top:3px;
  align-items: left;
`;

const MobVoucherStatus = styled.p`
font-size:12px;
margin-left:16px;
color: ${({ available }) => (available ? "green" : "red")};
    
@media (max-width: 768px) {

  margin-left:-2px;
 
}
`;

const MobCouponContainer = styled.div`

`;
const MobShopImage = styled.img`
  width: 60%;
  max-height: 100%;
  margin-left: 2%;
`;

const MobShopLink = styled.a`
  color: #405eab;
  text-decoration: underline;
  margin-top:10px;
  margin-left:5vw;
  font-size: 8px;
`;



const VoucherStatus = styled.p`
  font-size: 18px;
  color: ${({ available }) => (available ? "green" : "red")};
`;
const MobFamousPromoCode = styled.div`
  text-align: center;
  margin-top: 10px;
  font-size:12px;
  margin-left:-25px;
`;
const FamousPromoCode = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px; /* Adjust the margin as needed */
`;
const Button = styled.button`
  background-color: #ff3d44;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff5a5f;
  }
`;

const PromoCodeButton = styled.div`
  display: flex;
  align-items: center;
`;

const PromoCodeText = styled.span`
  margin-right: 10px;
`;

const CopyIcon = styled.i`
  /* Add styling for the copy icon */
`;

const ShopCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { shopName } = useParams();
  const [categories, setCategories] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetch(`https://discountparlour.com/api/categories?shop_name=${shopName}`)
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(
              `HTTP error! Status: ${response.status}, Message: ${errorData.error}`
            );
          });
        }
        return response.json();
      })
      .then((data) => setCategories(data))
      .catch((error) => {
        console.error("Error fetching categories:", error.message);
        setError(error.message); // Set the error state
      });

    fetch(`https://discountparlour.com/api/vouchers/shop?shop_name=${shopName}`)
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(
              `HTTP error! Status: ${response.status}, Message: ${errorData.error}`
            );
          });
        }
        return response.json();
      })
      .then((data) => {
        setCoupons(data);
        console.log("dsad");
        console.log(coupons);
        setLoaded(true); // Set loaded to true once data is fetched
      })
      .catch((error) => {
        console.error("Error fetching coupons:", error.message);
        setError(error.message); // Set the error state
      });
  }, [shopName]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Promocode copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  // Function to check if any coupons are available
  const isCouponAvailable = coupons.length > 0;

  return (
    <CouponsPageContainer className={`coupons-page ${loaded ? "loaded" : ""}`}>
      <Header />
      <Laptop>

      <ContentContainer1 className="container-shop">
        {/* Shop info section */}
        <ShopInfoContainer>
          {/* Check if coupons array is not empty and display shop details */}
          {coupons.length > 0 && (
            <ShopInfo>
              {/* Display shop image */}
              <ShopImage  src={`https://discountparlour.com/${coupons[0].shop_image_path}`} alt="Shop Image" />
              <div>
                {/* Display shop name */}
                <ShopName>
                  <ShopName>{shopName}</ShopName>
                  <ShopLink href={coupons[0].item_link}>Click Here to get All Offers!</ShopLink>
                </ShopName>
                {/* Display voucher status */}
                <VoucherStatus available={isCouponAvailable}>
                  {isCouponAvailable ? `Available Coupons: ${coupons.length}` : "Not available right now"}
                </VoucherStatus>
              </div>
              {/* Display coupon info */}
              <CouponContainer>
                <CouponInfo>
                  <CouponPromocode>{coupons[0].promocode}</CouponPromocode>
                  {coupons[0].promocode ? (
                  <CopyButton onClick={() => copyToClipboard(coupons[0].promocode)}>Copy</CopyButton>
                  ):
                (
                  <CopyButton onClick={() => copyToClipboard(coupons[0].item_link)}>Copy</CopyButton>
                )}
                </CouponInfo>
                <FamousPromoCode>Famous PromoCode 🔥</FamousPromoCode>
              </CouponContainer>

            </ShopInfo>
          )}
        </ShopInfoContainer>
      </ContentContainer1>
      </Laptop>

      <Mobile>
        
      <ContentContainer1 className="container-shop">
        {/* Shop info section */}
        <ShopInfoContainer>
          {/* Check if coupons array is not empty and display shop details */}
          {coupons.length > 0 && (
            <ShopInfo>
              {/* Display shop image */}
              <MobContainer>
              <MobShopImage 
              src={`https://discountparlour.com/${coupons[0].shop_image_path}`} alt="Shop Image" />
              
              <MobShopLink href={coupons[0].item_link}>Click Here to get All Offers!</MobShopLink>
              </MobContainer>
            
                  
           
             <MobContainer>
                {/* Display voucher status */}
                <MobShopName>{shopName}</MobShopName>
                <MobVoucherStatus available={isCouponAvailable}>
                  {isCouponAvailable ? `Available Coupons: ${coupons.length}` : "Not available right now"}
                </MobVoucherStatus>
          
              {/* Display coupon info */}
              <MobCouponContainer>
                <MobCouponInfo>
                  <CouponPromocode>{coupons[0].promocode}</CouponPromocode>
                  {coupons[0].promocode ? (
                  <CopyButton onClick={() => copyToClipboard(coupons[0].promocode)}>Copy</CopyButton>
                  ):
                (
                  <CopyButton onClick={() => copyToClipboard(coupons[0].item_link)}>Copy</CopyButton>
                )}
                </MobCouponInfo>
                <MobFamousPromoCode>Famous PromoCode 🔥</MobFamousPromoCode>
              </MobCouponContainer>
              </MobContainer>

            </ShopInfo>
          )}
        </ShopInfoContainer>
      </ContentContainer1>

      </Mobile>

      <ContentContainer className="container-shop">

        {/* Coupon list section */}
        <CouponListContainer className="coupon-list">
          <CouponListTitle>Get Free Coupons and Deals</CouponListTitle>


          <CouponList coupons={coupons} searchQuery={searchQuery} />
        </CouponListContainer>
      </ContentContainer>
      <Footer />
      <Rights />
    </CouponsPageContainer>
  );
};

export default ShopCoupons;