import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  label {
    display: block;
    margin-bottom: 10px;
  }

  input[type="text"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  input[type="file"] {
    margin-bottom: 20px;
  }

  button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
`;

const Error = styled.p`
  color: red;
`;

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState({
    name: "",
    image: null,
    off_percentage: 0,
    ends_in_timer: 0,
    description: "",
    promocode: "",
    shop__category: "",
    item_link: "",
    shop_name: "",
    shop_image: null,
    rating: 0,
  });
  const [error, setError] = useState(null);


  useEffect(() => {
    fetch(`https://discountparlour.com/api/coupons/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch coupon");
        }
        return response.json();
      })
      .then((data) => setCoupon(data))
      .catch((error) => setError(error.message));
  }, [id]);
  

  const handleEditCoupon = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(coupon).forEach((key) => {
      formData.append(key, coupon[key]);
    });

    fetch(`https://discountparlour.com/api/vouchers/${id}`, {
      method: "PUT",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        } else {
          navigate("/AdminPortal");
        }
      })
      .catch((error) => setError(error.message));
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setCoupon({ ...coupon, [name]: files[0] });
    } else {
      setCoupon({ ...coupon, [name]: value });
    }
  };

  return (
    <Container>
      <Title>Edit Coupon</Title>
      {error && <Error>{error}</Error>}
      <Form onSubmit={handleEditCoupon}>
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={coupon.name}
          onChange={handleChange}
        />
        <label>Image:</label>
        <input type="file" name="image" onChange={handleChange} />
        <label>Off Percentage:</label>
        <input
          type="number"
          name="off_percentage"
          value={coupon.off_percentage}
          onChange={handleChange}
        />
        <label>Ends in Timer:</label>
        <input
          type="number"
          name="ends_in_timer"
          value={coupon.ends_in_timer}
          onChange={handleChange}
        />
        <label>Description:</label>
        <textarea
          name="description"
          value={coupon.description}
          onChange={handleChange}
        />
        <label>Promocode:</label>
        <input
          type="text"
          name="promocode"
          value={coupon.promocode}
          onChange={handleChange}
        />
        <label>Shop Category:</label>
        <input
          type="text"
          name="shop__category"
          value={coupon.shop__category}
          onChange={handleChange}
        />
        <label>Item Link:</label>
        <input
          type="text"
          name="item_link"
          value={coupon.item_link}
          onChange={handleChange}
        />
        <label>Shop Name:</label>
        <input
          type="text"
          name="shop_name"
          value={coupon.shop_name}
          onChange={handleChange}
        />
        <label>Shop Image:</label>
        <input type="file" name="shop_image" onChange={handleChange} />
        <label>Rating:</label>
        <input
          type="number"
          name="rating"
          value={coupon.rating}
          onChange={handleChange}
        />
        <button type="submit">Save Changes</button>
      </Form>
    </Container>
  );
};

export default Edit;
