// AboutUs.jsx

import React from "react";
import "./../css/AboutUs.css"; // Include your own stylesheet
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <Header />

      <div className="about-content">
        <h1>About Us</h1>
        <p>
          Welcome to Discount Shop, where we believe in the joy of saving and
          the happiness it brings to people's lives. Our mission is simple – to
          make your shopping experience more delightful and budget-friendly by
          offering a plethora of free coupons. We understand the value of your
          hard-earned money, and we are dedicated to helping you save more on
          every purchase.
        </p>
        <p>
          At Discount Shop, we are more than just a coupon platform; we are a
          community that celebrates the art of smart shopping. We curate the
          best deals, discounts, and exclusive offers to ensure you get the
          maximum value for your money. Our goal is to empower you to make
          informed decisions and enjoy a stress-free shopping journey.
        </p>
        <p>
          As a team, we are passionate about bringing happiness into your life
          through savings. Every coupon shared on our platform is a small step
          towards creating moments of joy and fulfillment. Join us on this
          exciting journey, explore our wide range of free coupons, and let's
          make every purchase a celebration!
        </p>
      </div>

      <Footer />
      <Rights />
    </div>
  );
};

export default AboutUs;
