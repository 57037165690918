// NoDealsYet.jsx

import React from "react";
import "./../css/NoDealsYet.css"; // Include your own stylesheet
import Header from "./../Components/Header";
import Footer from "./../Components/Footer";
import Rights from "./../Components/CompanyRights";

const underConstruction = () => {
  return (
    <div className="no-deals-yet-container">
      <Header />

      <div className="no-deals-yet-content">
        <h1>No Deals Yet</h1>
        <p>
          We apologize, but there are currently no deals available. Our team is
          constantly working to bring you the latest and greatest savings.
          Please check back later or explore other sections of our website.
        </p>

        {/* Add under construction icon (replace the emoji with your construction icon) */}
        <div className="under-construction-icon">🚧</div>
        <p>This page is under construction. Check back soon!</p>
      </div>

      <Footer />
      <Rights />
    </div>
  );
};

export default underConstruction;
