import React, { useState, useEffect  , useCallback} from "react";
import AdminHeader from "./../Components/AdminHeader";
import "./../css/admin_portal.css";
import { useParams, useNavigate } from "react-router-dom";
const AdminPortal = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const navigate = useNavigate();
  const [newCoupon, setNewCoupon] = useState({
    name: "",
    image: null, // Changed to null, as it will hold the file object
    off_percentage: 0,
    ends_in_timer: 0,
    description: "",
    promocode: "",
    shop__category: "",
    item_link: "",
    shop_name: "",
    shop_image: null,
    rating: 0,
  });

  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: "",
  });

  const handleFeaturedCoupon = (id) => {
    fetch(`https://discountparlour.com/api/vouchers/feature/${id}`, {
      method: "PUT",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
        // Update the coupons after changing the feature status
        getVoucher();
      })
      .catch((error) => console.error("Error updating feature status:", error));
  };

  const handleUnFeaturedCoupon = (id) => {
    fetch(`https://discountparlour.com/api/vouchers/feature/${id}`, {
      method: "PUT",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
        // Update the coupons after changing the feature status
        getVoucher();
      })
      .catch((error) => console.error("Error updating feature status:", error));
  };

  const handleAdminLogin = () => {
    const { username, ...rest } = loginCredentials;
    console.log("Sending login request with credentials:", {
      username,
      ...rest,
    });
    fetch("https://discountparlour.com/api/admin/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginCredentials),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsAdmin(true);
          getVoucher();
          localStorage.setItem("token", "your_admin_token"); // Replace with your actual admin token
        } else {
          setIsAdmin(false);
          localStorage.removeItem("token");
        }
      })
      .catch((error) => console.error("Error authenticating admin:", error));
  };

  const getVoucher = () => {
    fetch("https://discountparlour.com/api/vouchers", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setIsAdmin(true);
          return response.json();
        } else {
          setIsAdmin(false);
          return [];
        }
      })
      .then((data) => setCoupons(data))
      .catch((error) => console.error("Error fetching data:", error));
  };
  const handleAddCoupon = () => {
    console.log(newCoupon.name);
    const formData = new FormData();
    formData.append("name", newCoupon.name);
    formData.append("off_percentage", newCoupon.off_percentage);
    formData.append("ends_in_timer", newCoupon.ends_in_timer);
    formData.append("description", newCoupon.description);
    formData.append("promocode", newCoupon.promocode);
    formData.append("item_link", newCoupon.item_link);
    formData.append("shop_category", newCoupon.shop__category);
    formData.append("shop_name", newCoupon.shop_name);
    formData.append("image", newCoupon.image);
    formData.append("shop_image", newCoupon.shop_image);
    formData.append("rating", newCoupon.rating);
  
    console.log(newCoupon.shop__category);
  
    fetch("https://discountparlour.com/api/vouchers", {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          // Handle error from the API
          console.log("dadasd" + data.error);
        } else {
          setCoupons([...coupons, data]);
          setNewCoupon({
            name: "",
            image: null,
            off_percentage: 0,
            ends_in_timer: 0,
            shop_image: null,
            description: "",
            promocode: "",
            item_link: "",
            shop__category: "",
            shop_name: "",
            rating: 0,
          });
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error("Error:", error);
      });
  };
  
  

  const handleDeleteCoupon = (id) => {
    fetch(`https://discountparlour.com/api/vouchers/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then(() => setCoupons(coupons.filter((coupon) => coupon.id !== id)))
      .catch((error) => console.error("Error deleting coupon:", error));
  };

  const handleImageChange = (e) => {
    // Set the 'image' property in the 'newCoupon' state to the selected file
    setNewCoupon({ ...newCoupon, image: e.target.files[0] });
  };

  const handleShopImageChange = (e) => {
    // Set the 'image' property in the 'newCoupon' state to the selected file
    setNewCoupon({ ...newCoupon, shop_image: e.target.files[0] });
  };

  return (
    <div className="admin-portal">
      {isAdmin ? (
        <>
          <AdminHeader />
          <div className="content-container">
            <main>
              <div className="admin-form">
                <h2>Add New Coupon</h2>
                <label>Name:</label>
                <input
                  type="text"
                  value={newCoupon.name}
                  onChange={(e) =>
                    setNewCoupon({ ...newCoupon, name: e.target.value })
                  }
                />
                <label>Image:</label>
                <input
                  type="file" // Use 'file' input type for image uploads
                  accept="image/*" // Specify accepted file types (images)
                  onChange={handleImageChange} // Handle the file change event
                />
                <label>Shop Image:</label>
                <input
                  type="file" // Use 'file' input type for image uploads
                  accept="image/*" // Specify accepted file types (images)
                  onChange={handleShopImageChange} // Handle the file change event
                />
                <label>Discount Percent:</label>
                <input
                  type="number"
                  value={newCoupon.off_percentage}
                  onChange={(e) =>
                    setNewCoupon({
                      ...newCoupon,
                      off_percentage: e.target.value,
                    })
                  }
                />
                <label>Ends in Timer:</label>
                <input
                  type="number"
                  value={newCoupon.ends_in_timer}
                  onChange={(e) =>
                    setNewCoupon({
                      ...newCoupon,
                      ends_in_timer: e.target.value,
                    })
                  }
                />
                <label>Description:</label>
                <input
                  type="text"
                  value={newCoupon.description}
                  onChange={(e) =>
                    setNewCoupon({ ...newCoupon, description: e.target.value })
                  }
                />
                <label>Promocode:</label>
                <input
                  type="text"
                  value={newCoupon.promocode}
                  onChange={(e) =>
                    setNewCoupon({ ...newCoupon, promocode: e.target.value })
                  }
                />
                <label>Item Link:</label>
                <input
                  type="text"
                  value={newCoupon.item_link}
                  onChange={(e) =>
                    setNewCoupon({ ...newCoupon, item_link: e.target.value })
                  }
                />
                <label>Shop Name:</label>
                <input
                  type="text"
                  value={newCoupon.shop_name}
                  onChange={(e) =>
                    setNewCoupon({ ...newCoupon, shop_name: e.target.value })
                  }
                />
                <label>Category:</label>
                <input
                  type="text"
                  value={newCoupon.shop__category}
                  onChange={(e) =>
                    setNewCoupon({
                      ...newCoupon,
                      shop__category: e.target.value,
                    })
                  }
                />
                <label>Rating:</label>
                <input
                  type="number"
                  value={newCoupon.rating}
                  onChange={(e) =>
                    setNewCoupon({ ...newCoupon, rating: e.target.value })
                  }
                />
                <button onClick={handleAddCoupon}>Add Coupon</button>
              </div>
              <div className="coupon-list-admin">
                <h2>Coupon List</h2>
                {coupons.map((coupon) => (
                  <div key={coupon.id} className="coupon-admin">
                    <h3>{coupon.name}</h3>
                    <p>Description: {coupon.description}</p>
                    <p>Discount Percent: {coupon.discount_percent}%</p>
                    <p>Quantity: {coupon.quantity}</p>
                    <p>Shop Name: {coupon.shop_name}</p>
                    <p>Item: {coupon.item}</p>
                    <button onClick={() => handleDeleteCoupon(coupon.id)}>
                      Delete
                    </button>
                    <button onClick={() => navigate(`/edit/${coupon.id}`)}>
                      Edit
                    </button>
                    {coupon.is_featured ? (
                      <button
                        className="featured"
                        onClick={() => handleFeaturedCoupon(coupon.id)}
                      >
                        Featured
                      </button>
                    ) : (
                      <button
                        className="unfeatured"
                        onClick={() => handleUnFeaturedCoupon(coupon.id)}
                      >
                        Unfeatured
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </main>
          </div>
          <div className="footer">
            <p>&copy; 2024 [Your Brand]. All rights reserved.</p>
          </div>
        </>
      ) : (
        <div className="admin-login-form">
          <h2>Admin Login</h2>
          <label>Username:</label>
          <input
            type="text"
            value={loginCredentials.username}
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                username: e.target.value,
              })
            }
          />
          <label>Password:</label>
          <input
            type="password"
            value={loginCredentials.password}
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                password: e.target.value,
              })
            }
          />
          <button onClick={handleAdminLogin}>Login</button>
        </div>
      )}
    </div>
  );
};

export default AdminPortal;
