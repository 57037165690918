import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../css/GetOffer.css";
import { useNavigate } from "react-router-dom";

const GetOffer = ({ couponDetails, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(couponDetails.promocode).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000); // Reset after 3 seconds (adjust as needed)
    });
  };

  const handleItemClick = () => {
    window.open(couponDetails.item_link, "_blank");
  };

  const handleSubscribeClick = async () => {
    try {
      const response = await fetch(
        "https://discountparlour.com/api/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        // Handle successful subscription
        setIsSubscribed(true);
        setEmail("");
        toast.success("Subscription successful");
      } else {
        const errorData = await response.json(); // Parse the error response
        console.log("aDs", errorData); // Log the error data for inspection
        const errorMessage = errorData.error || "Subscription failed"; // Get error message from response or use a default message
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error during subscription");
    }
  };

  const handleShopSelect = (selectedShop) => {
    onClose();
    navigate(`/ShopCoupons/${selectedShop.shop_name}`);
  };

  return (
    <div className="get-offer-modal">
      <div className="close-button" onClick={onClose}>
        &times;
      </div>
      <div className="modal-content">
        <div className="main-content">
          <div className="before">
            <div>
              {couponDetails.image_path && (
                <img
                 
                src={`https://discountparlour.com/${couponDetails.image_path}`}
                  alt="Coupon Image"
                  className="modal-image"
                />
              )}
            </div>
            <div className="modal-details">
              <h3>{couponDetails.name}</h3>
              <p className="discount">{couponDetails.off_percentage}% off</p>
              <p className="modal-description">{couponDetails.description}</p>
              <div className="promocode-container">
                
                {couponDetails.promocode ? (
                  <>
                    <p className="promocode">
                      Promocode: <span>{couponDetails.promocode}</span>
                      <button
                        onClick={handleCopyClick}
                        disabled={isCopied}
                        className="copybutton"
                      >
                        {isCopied ? "Copied" : <FontAwesomeIcon icon={faCopy} />}
                      </button>
                    </p>
                  </>
                ) : (
                  <p className="promocode" style={{ color: 'green' }}>
                    No promo code, just click on get offer to avail discount
                  </p>
                )}
                {couponDetails.item_link && (
                  <p className="item-link">
                    <span>Click to get offer: </span>
                    <button
                      onClick={handleItemClick}
                      className="item-link-button"
                    >
                      {couponDetails.shop_name}
                    </button>
                  </p>
                )}

                <p className="item-link">
                  <button
                    onClick={() => handleShopSelect(couponDetails)}
                    className="shopcoupons-button"
                  >
                    Get More Coupons of {couponDetails.shop_name}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr className="separator-line" />

        <div className="subscribe-section">
          <p className="blue-text">
            Never miss a great{" "}
            <span className="coupon-namecolor">{couponDetails.name}</span>{" "}
            coupon, and so many more!
          </p>
          <div className="email">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handleSubscribeClick}
              disabled={isSubscribed}
              className={isSubscribed ? "subscribed" : ""}
            >
              {isSubscribed ? "Subscribed" : "Subscribe"}
            </button>
          </div>

          <p className="small-text">
            By clicking the Subscribe button, I agree to the
            <a
              href="/Terms&Conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="blue-link"
            >
              {" "}
              Terms of Use
            </a>{" "}
            and have read the
            <a
              href="/PrivacyPolicy"
              target="_blank"
              rel="noopener noreferrer"
              className="blue-link"
            >
              {" "}
              Privacy Statement
            </a>
            .
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default GetOffer;
